export const tilemapjson = { "compressionlevel":-1,
"height":32,
"infinite":false,
"layers":[
       {
        "data":[347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347,
           347, 347, 347, 347, 347, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 347, 347, 347,
           347, 347, 347, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 347, 347,
           347, 347, 65, 65, 65, 65, 15, 15, 2, 2, 8, 9, 13, 11, 9, 10, 16, 9, 10, 15, 16, 8, 18, 11, 10, 18, 5, 1073741826, 65, 65, 65, 347,
           347, 347, 65, 65, 2, 15, 22, 2, 5, 9, 10, 15, 16, 15, 21, 21, 17, 14, 9, 17, 14, 14, 9, 10, 15, 17, 20, 18, 1073741826, 65, 65, 347,
           347, 347, 65, 65, 18, 8, 2, 2, 16, 2, 8, 9, 18, 17, 14, 14, 16, 22, 11, 8, 19, 14, 14, 16, 9, 10, 11, 8, 19, 65, 65, 347,
           347, 347, 65, 65, 2, 2, 5, 17, 18, 9, 13, 8, 11, 12, 10, 8, 22, 9, 10, 11, 21, 11, 22, 12, 13, 8, 17, 8, 11, 65, 65, 347,
           347, 347, 65, 65, 15, 15, 9, 13, 2, 11, 8, 16, 14, 17, 18, 84, 84, 69, 69, 18, 8, 9, 5, 11, 18, 22, 16, 12, 13, 65, 65, 347,
           347, 347, 65, 65, 17, 8, 2, 11, 8, 2, 12, 13, 20, 14, 84, 68, 69, 69, 69, 79, 91, 12, 13, 8, 12, 13, 9, 15, 65, 65, 65, 347,
           347, 347, 65, 65, 8, 21, 15, 17, 14, 16, 2, 2, 13, 84, 84, 69, 73, 74, 91, 81, 78, 82, 91, 91, 13, 8, 8, 65, 65, 65, 347, 347,
           347, 347, 65, 65, 9, 13, 17, 8, 17, 14, 16, 8, 84, 84, 69, 69, 72, 69, 79, 85, 91, 69, 65, 84, 78, 22, 8, 65, 65, 347, 347, 347,
           347, 347, 65, 65, 2, 2, 8, 12, 20, 22, 11, 13, 69, 72, 71, 67, 72, 69, 83, 78, 84, 88, 68, 66, 88, 91, 22, 65, 65, 347, 347, 347,
           347, 347, 65, 65, 19, 20, 11, 17, 14, 9, 2, 72, 76, 86, 68, 72, 68, 74, 81, 86, 82, 73, 75, 66, 73, 82, 74, 65, 65, 347, 347, 347,
           347, 347, 65, 65, 65, 2, 15, 15, 16, 2, 65, 82, 73, 65, 69, 67, 68, 68, 79, 68, 68, 81, 83, 68, 68, 67, 67, 65, 65, 347, 347, 347,
           347, 347, 65, 65, 65, 65, 15, 10, 8, 10, 65, 90, 84, 91, 65, 65, 74, 78, 87, 68, 76, 65, 65, 71, 78, 72, 76, 65, 65, 65, 347, 347,
           347, 347, 347, 347, 65, 65, 65, 15, 20, 17, 65, 85, 86, 65, 65, 71, 68, 68, 90, 91, 69, 65, 65, 80, 69, 85, 18, 18, 65, 65, 347, 347,
           347, 347, 347, 347, 347, 65, 65, 17, 2, 2, 65, 65, 86, 75, 80, 66, 67, 88, 86, 65, 65, 89, 86, 85, 72, 70, 9, 22, 65, 65, 347, 347,
           347, 347, 347, 347, 347, 65, 65, 17, 13, 2, 65, 75, 88, 80, 74, 68, 71, 72, 1067, 68, 68, 81, 80, 66, 80, 78, 18, 19, 65, 65, 347, 347,
           347, 347, 347, 347, 65, 65, 65, 8, 12, 65, 91, 75, 86, 82, 72, 85, 69, 76, 77, 68, 73, 74, 86, 81, 88, 15, 10, 12, 65, 65, 347, 347,
           347, 347, 347, 65, 65, 65, 2, 21, 19, 65, 80, 71, 80, 68, 65, 76, 77, 68, 68, 77, 83, 70, 69, 83, 68, 15, 15, 18, 65, 65, 347, 347,
           347, 347, 347, 65, 65, 65, 15, 11, 19, 65, 91, 90, 73, 67, 69, 85, 86, 65, 86, 82, 65, 83, 78, 78, 68, 1073741826, 10, 18, 65, 65, 347, 347,
           347, 347, 65, 65, 65, 21, 9, 22, 18, 65, 78, 87, 91, 76, 65, 79, 66, 68, 79, 89, 82, 66, 65, 65, 11, 12, 19, 65, 65, 65, 347, 347,
           347, 347, 65, 65, 17, 18, 17, 19, 20, 65, 90, 91, 68, 67, 74, 68, 79, 80, 76, 85, 65, 89, 1067, 88, 17, 16, 13, 65, 65, 347, 347, 347,
           347, 347, 65, 65, 16, 9, 10, 2, 21, 2, 71, 88, 89, 65, 83, 65, 89, 85, 65, 88, 90, 76, 77, 80, 14, 16, 13, 65, 65, 347, 347, 347,
           347, 347, 65, 65, 8, 20, 19, 16, 22, 20, 8, 66, 87, 89, 67, 85, 74, 86, 84, 89, 71, 88, 93, 72, 12, 8, 17, 65, 65, 347, 347, 347,
           347, 347, 65, 65, 18, 21, 12, 10, 18, 20, 2, 19, 11, 69, 74, 81, 65, 91, 85, 72, 92, 78, 89, 11, 18, 14, 65, 65, 65, 347, 347, 347,
           347, 347, 65, 65, 9, 10, 21, 15, 17, 22, 9, 17, 22, 10, 65, 65, 92, 72, 76, 75, 90, 89, 18, 12, 19, 65, 65, 65, 65, 347, 347, 347,
           347, 347, 65, 65, 65, 65, 65, 5, 2, 19, 22, 9, 10, 11, 21, 5, 11, 72, 72, 72, 72, 16, 16, 65, 65, 65, 65, 65, 347, 347, 347, 347,
           347, 347, 347, 44, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 347, 347, 347, 347, 347,
           347, 347, 347, 347, 347, 347, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 347, 347, 347, 347, 347, 347, 347, 347,
           347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347,
           347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347, 347],
        "height":32,
        "id":1,
        "name":"floor",
        "opacity":1,
        "type":"tilelayer",
        "visible":true,
        "width":32,
        "x":0,
        "y":0
       }, 
       {
        "data":[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 375, 363, 363, 363, 363, 363, 363, 363, 363, 363, 363, 363, 363, 363, 363, 363, 363, 363, 363, 363, 363, 363, 363, 374, 0, 0, 0,
           0, 0, 0, 375, 363, 376, 37, 43, 43, 43, 43, 43, 43, 43, 43, 43, 43, 43, 43, 43, 43, 43, 43, 43, 43, 43, 43, 38, 373, 374, 0, 0,
           0, 0, 375, 376, 37, 43, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 38, 373, 383, 0,
           0, 0, 361, 37, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 38, 362, 0,
           0, 0, 361, 41, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 42, 362, 0,
           0, 0, 361, 41, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 42, 362, 0,
           0, 0, 361, 41, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 44, 44, 44, 44, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 40, 362, 0,
           0, 0, 361, 41, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 40, 0, 0, 0, 0, 39, 44, 0, 0, 0, 0, 0, 0, 0, 40, 369, 370, 0,
           0, 0, 361, 41, 0, 0, 0, 0, 0, 0, 0, 0, 0, 40, 0, 0, 0, 0, 0, 0, 0, 39, 44, 44, 0, 0, 0, 42, 369, 370, 0, 0,
           0, 0, 377, 41, 0, 0, 0, 0, 0, 0, 0, 0, 42, 0, 0, 0, 0, 0, 0, 0, 0, 0, 98, 0, 39, 0, 0, 42, 362, 0, 0, 0,
           0, 0, 361, 41, 0, 0, 0, 0, 0, 0, 0, 0, 40, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 39, 0, 42, 362, 0, 0, 0,
           0, 0, 361, 41, 0, 0, 0, 0, 0, 0, 0, 40, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 41, 42, 362, 0, 0, 0,
           0, 0, 361, 39, 44, 0, 0, 0, 0, 0, 42, 0, 0, 97, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 41, 42, 362, 0, 0, 0,
           0, 0, 371, 364, 372, 39, 0, 0, 0, 0, 42, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 41, 42, 373, 374, 0, 0,
           0, 0, 0, 0, 371, 372, 41, 0, 0, 0, 42, 0, 109, 110, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 37, 0, 0, 38, 362, 0, 0,
           0, 0, 0, 0, 0, 361, 41, 0, 0, 0, 42, 97, 0, 0, 0, 0, 98, 0, 0, 0, 0, 0, 0, 0, 0, 41, 0, 0, 42, 362, 0, 0,
           0, 0, 0, 0, 0, 361, 41, 0, 0, 0, 40, 0, 0, 111, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 41, 0, 0, 42, 378, 0, 0,
           0, 0, 0, 0, 375, 376, 41, 0, 0, 42, 0, 0, 0, 112, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 37, 0, 0, 0, 42, 362, 0, 0,
           0, 0, 0, 375, 376, 37, 0, 0, 0, 42, 0, 0, 0, 0, 98, 0, 0, 0, 0, 0, 111, 0, 0, 0, 41, 0, 0, 0, 42, 362, 0, 0,
           0, 0, 0, 361, 0, 41, 0, 0, 0, 42, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 112, 0, 0, 0, 41, 0, 0, 0, 40, 362, 0, 0,
           0, 0, 384, 376, 37, 0, 0, 0, 0, 42, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 98, 37, 0, 0, 0, 42, 369, 370, 0, 0,
           0, 0, 361, 37, 0, 0, 0, 0, 0, 42, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 97, 0, 0, 41, 0, 0, 0, 42, 362, 0, 0, 0,
           0, 0, 361, 41, 0, 0, 0, 0, 0, 0, 38, 0, 0, 0, 0, 0, 0, 0, 98, 0, 0, 0, 0, 41, 0, 0, 0, 42, 378, 0, 0, 0,
           0, 0, 361, 41, 0, 0, 0, 0, 0, 0, 0, 43, 38, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 41, 0, 0, 0, 42, 362, 0, 0, 0,
           0, 0, 361, 41, 0, 0, 0, 0, 0, 0, 0, 0, 0, 38, 0, 0, 0, 0, 0, 0, 0, 0, 37, 0, 0, 0, 44, 40, 362, 0, 0, 0,
           0, 0, 361, 39, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 43, 43, 38, 0, 0, 0, 0, 37, 0, 0, 0, 40, 0, 369, 381, 0, 0, 0,
           0, 0, 371, 372, 39, 44, 44, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 43, 43, 43, 43, 0, 0, 44, 40, 0, 369, 370, 0, 0, 0, 0,
           0, 0, 0, 371, 364, 364, 372, 39, 44, 44, 44, 44, 44, 44, 44, 44, 44, 44, 44, 44, 44, 44, 40, 369, 364, 364, 370, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 382, 364, 364, 364, 364, 364, 364, 364, 364, 364, 364, 364, 364, 380, 364, 364, 364, 370, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        "height":32,
        "id":9,
        "name":"floor2",
        "opacity":1,
        "type":"tilelayer",
        "visible":true,
        "width":32,
        "x":0,
        "y":0
       }, 
       {
        "data":[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 567, 0, 306, 308, 305, 306, 308, 307, 306, 307, 307, 305, 306, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 564, 0, 0, 307, 308, 307, 0, 306, 301, 302, 307, 308, 306, 0, 305, 0, 308, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 307, 307, 305, 0, 308, 0, 325, 326, 0, 0, 308, 306, 0, 307, 0, 305, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 437, 438, 0, 435, 423, 436, 437, 438, 0, 0, 0, 308, 307, 307, 0, 307, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 571, 0, 0, 435, 441, 423, 436, 0, 0, 0, 427, 439, 428, 0, 0, 0, 0, 306, 307, 306, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 567, 566, 570, 423, 436, 0, 0, 0, 0, 0, 0, 566, 567, 424, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 564, 564, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 571, 0, 0, 0, 0, 306, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 566, 569, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 308, 306, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 565, 568, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 305, 305, 307, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1069, 0, 0, 0, 0, 0, 0, 0, 0, 308, 306, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 566, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1069, 0, 0, 0, 0, 0, 0, 0, 0, 0, 306, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1077, 1068, 1068, 1074, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 564, 0, 0, 0, 0, 0, 1068, 1075, 0, 0, 1069, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1069, 0, 0, 1069, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 570, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 565, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 566, 569, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 566, 571, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 564, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 306, 306, 0, 565, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 305, 305, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 308, 308, 308, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 308, 306, 306, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 306, 306, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 306, 305, 308, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 565, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        "height":32,
        "id":10,
        "name":"floor3",
        "opacity":1,
        "type":"tilelayer",
        "visible":true,
        "width":32,
        "x":0,
        "y":0
       }, 
       {
        "data":[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1135, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1125, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1125, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1125, 0, 0, 1135, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
           0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        "height":32,
        "id":11,
        "name":"buildings",
        "opacity":1,
        "type":"tilelayer",
        "visible":true,
        "width":32,
        "x":0,
        "y":0
       }],
"nextlayerid":12,
"nextobjectid":249,
"orientation":"orthogonal",
"renderorder":"right-down",
"tiledversion":"1.10.2",
"tileheight":16,
"tilesets":[
       {
        "columns":32,
        "firstgid":1,
        "image":"overworld.png",
        "imageheight":640,
        "imagewidth":512,
        "margin":0,
        "name":"tiles",
        "spacing":0,
        "tilecount":1280,
        "tileheight":16,
        "tilewidth":16
       }],
"tilewidth":16,
"type":"map",
"version":"1.10",
"width":32
}
